import React, { Component } from 'react'
import { Button, Card, CardBody, CardFooter, FormControl, InputGroup } from 'react-bootstrap'
import { LoadSerieName } from '../../../store/Metadata'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import db from '../../../firebase'

export default class NameSeries extends Component {
    constructor(props) {
        super(props)
        this.state = LoadSerieName.init
    }

    async componentDidMount() {

        const serieList = await getDocs(collection(db, 'series_names'));


        try {
            serieList.forEach((nsame, index, inside) => {
                /* this.setState((prevState) => ({
                     loadList : [...prevState, nsame]
                 }))*/
                console.log(nsame.data().noString)
            })

        } catch (e) { }

    }

    handleBtnNewNameSerie() {

        if (this.state.nameSerie !== '') {

            const serieUpload = doc(db, 'series_names', this.state.nameSerie)

            try {
                setDoc(serieUpload, {
                    nameSerie: this.state.nameSerie,
                    idSerie: this.state.codeEve,
                    portadaSerie: '',
                })
                let cleanTxt = document.getElementById('#nameserie')
                cleanTxt.value = ''
                this.setState({
                    nameSerie: ''
                })
            } catch (e) {

            }
        }
    }




    render() {
        return (
            <div style={{ marginLeft: '5%' }}>
                <Card>
                    <CardBody>
                        <span>peliculas agregadas</span>
                        <span>ESRB</span>
                        <InputGroup>
                            <select onChange={(e) => { this.setState({ nameSerie: e.target.value }) }}>
                                {this.state.loadList.map((nameSerie, id) => (
                                    <option key={id}>{nameSerie}</option>
                                ))}
                            </select>
                        </InputGroup>
                        <br />
                        <InputGroup>
                            <FormControl
                                id='nameserie'
                                type='text'
                                placeholder='Ingresa el nombre de la serie'
                                onChange={(e) => { this.setState({ nameSerie: e.target.value }) }}
                            />
                        </InputGroup>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.handleBtnNewNameSerie() }}>Agregar Serie</Button>
                    </CardFooter>
                </Card>
            </div>
        )
    }

}
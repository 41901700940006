import React, { useEffect } from 'react';


const BannerText = () => {


  return (
    <>
    
    <div className="banner-container">
      <h1 className="color-sliders">广告！！！</h1>
    </div>
    </>
  );
};

export default BannerText;
import React, { Component } from 'react';

export default class SearchCodeMov extends Component {
  constructor(props) {
    super(props);
    this.handlePlay = this.handlePlay.bind(this);
  }

  handlePlay() {
    this.props.chooseMovie(this.props.movie);
    console.log('sajo')
  }

  render() {
    const { movie } = this.props;
    const image = 'https://image.tmdb.org/t/p/original';

    return (
      <div onClick={this.handlePlay} className="d-flex  align-items-center b-gren-color" style={{ cursor: 'pointer' }}>
        <img          
          src={image + movie.poster_path}
          style={{ height: '64px', width: '64px' }}
        />
        <div className="ml-3">
          <div>{movie.title}</div>
          <div className="text-muted">{movie.name}</div>
        </div>
      </div>
    );
  }
}
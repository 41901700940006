import React, { Component } from 'react';
import { LoadSidebar } from '../../store/Metadata';
import { Col, Row, Nav } from 'react-bootstrap';
import NewVideo from './Archive/NewVideo';
import AdminVideo from './Archive/AdminVideo';
import ContentSqare from './Archive/ContentSqare';
import AdminGame from './GamerFrame/AdminGame';
import NameSeries from './Archive/NameSeries';
import NameSaga from './Archive/NameSaga';


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = LoadSidebar.icon
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };


  handleButton(i) {   
    i === 0 ? this.setState({
      videoSection: false,
      gameSection: false,
      tvSection: true,
      musicSection: false,
    }) : i === 1 ? this.setState({
      videoSection: true,
      gameSection: false,
      tvSection: false,
      musicSection: false,
    }) : i === 2 ? this.setState({
      videoSection: false,
      gameSection: false,
      tvSection: false,
      musicSection: true,
    }) : i === 3 ? this.setState({
      videoSection: false,
      gameSection: true,
      tvSection: false,
      musicSection: false,
    }) : <></>
  }

  render() {
    return (
      <>
        <Nav className='nav-admin-main'>
          {this.state.mainOption.map((d, i) => {
            return (
              <Nav.Item key={i}>
                <Nav.Link onClick={() => this.handleButton(i)}>
                  {d}
                </Nav.Link>
              </Nav.Item>
            )
          })}
        </Nav>
        {this.state.videoSection === true ? <Row className='video-plus-section'>
          <div className='sidebar-admin col'>
            <div className="menu">
              <ul>
                {this.state.dashVideoIcon.map((item, index) => (
                  <li key={index} onClick={() => { this.setState({ selectOption: item.name }); }}>
                    {item.icon}
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Col className='card-content-video'>
            {this.state.selectOption === 'Dashboard' ? <div>Dashboard</div> :
              this.state.selectOption === 'Admin de contenido' ? <AdminVideo /> :
                this.state.selectOption === 'Nuevo Video' ? <NewVideo /> :
                  this.state.selectOption === 'Contenido' ? <ContentSqare /> :
                    this.state.selectOption === 'Artistas' ? <div>Admin Video</div> :
                      this.state.selectOption === 'Categoria' ? <div>Admin Video</div> :
                        this.state.selectOption === 'Nombre Series' ? <NameSeries/> :
                          this.state.selectOption === 'Sagas por mintear' ? <NameSaga/> :
                            this.state.selectOption === 'Fuentes' ? <div>Fuentes</div> :
                              <></>}
          </Col>
        </Row> : <></>}
        {this.state.gameSection === true ? <Row>
          <div className='sidebar-admin col'>
            <div className="menu">
              <ul>
                {this.state.dashGameIcon.map((item, index) => (
                  <li key={index} onClick={() => { this.setState({ selectOption: item.name }); }}>
                    {item.icon}
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Col className='card-content-video'>
            {this.state.selectOption === 'Dashboard' ? <div>Dashboard</div> :
              this.state.selectOption === 'Admin de juegos' ? <NewVideo /> :
                this.state.selectOption === 'Enrutar Rom' ?  <AdminGame />:
                  this.state.selectOption === 'NFT' ? <ContentSqare /> :
                    this.state.selectOption === 'Artistas' ? <div>Admin Video</div> :
                      this.state.selectOption === 'Desarrolladora' ? <div>Admin Video</div> :
                        this.state.selectOption === 'Categoria' ? <div>Admin Video</div> :
                          this.state.selectOption === 'Clasificacion' ? <div>Admin Video</div> :
                            <></>}
          </Col>

        </Row> : <></>}

      </>
    );
  }
}

export default Sidebar;
import React, { Component } from 'react'

export default class NuveeBout extends Component {
    render() {
        return (
            <div>
                <h3> Nuvee 
                    
                    </h3>
                    <br></br>
                    <h4>Bienvenido a mi casa, disfruta del mejor contenido☁️ !!</h4>
            </div>
        )
    }
}

import React, { Component } from 'react';

class NavApp extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.animationFrameId = null;
    this.animationFrameScreen = null;
    this.currentColor = [0, 255, 0]; 
    this.targetColor = [128, 0, 128]; 
  }

  componentDidMount() {
    this.draw();
    if (this.props.effect === 'custom') {
      this.animateGradient();
    }
    if (this.props.effect === 'loop') {
      this.animateLoop();
    }
    if (this.props.effect === 'claqueta') {
      this.animate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.effect !== this.props.effect || prevProps.direction !== this.props.direction) {
      this.clearCanvas();
      this.draw();
    }
    if (prevProps.effect !== this.props.effect) {
      if (this.props.effect === 'custom') {
        this.animateGradient();
      } else {
        cancelAnimationFrame(this.animationFrameScreen);
      }
    }
    if (prevProps.effect !== this.props.effect && this.props.effect === 'claqueta') {
      this.animate();
    }
  }

  draw() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const imageData = ctx.createImageData(width - 25, height - 25);
    const data = imageData.data;
    if (this.props.effect === 'static') {
      for (let i = 0; i < data.length; i += 4) {
        const color = Math.random() > 0.5 ? 255 : 0;
        data[i] = color;        
        data[i + 1] = color;    
        data[i + 2] = color;    
        data[i + 3] = 255;    
      }
      this.animationFrameId = requestAnimationFrame(this.animateStatic.bind(this, ctx, imageData));
    }

    if (this.props.effect === 'loop') {   
      const colorCSS = `rgb(${this.currentColor[0]}, ${this.currentColor[1]}, ${this.currentColor[2]})`;
      ctx.fillStyle = colorCSS;
      ctx.fillRect(0, 0, width - 12, height - 12);
    }
    if (this.props.effect === 'icon' && this.props.direction) {
      const image = new Image();
      image.src = this.props.direction;
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    }
    if (this.props.effect === 'image' && this.props.direction) {
      const image = new Image();
      image.src = this.props.direction;
      image.onload = () => {
        ctx.drawImage(image, 0, 15, canvas.width, canvas.height - 29);
      };
    }

    if(this.props.effect === ' claqueta' ){
      ctx.clearRect(0, 0, width, height);

      // Dibujar la claqueta
      ctx.fillStyle = '#ffffff'; // Color blanco
      ctx.fillRect(0, 0, width, height);
  
      // Dibujar las palancas
      const leverWidth = 5;
      const leverHeight = height / 2;
      const leverGap = 10;
      ctx.fillStyle = '#000000'; // Color negro
      ctx.fillRect(leverGap, (height - leverHeight) / 2, leverWidth, leverHeight); // Palanca izquierda
      ctx.fillRect(width - leverGap - leverWidth, (height - leverHeight) / 2, leverWidth, leverHeight); // Palanca derecha
  
      // Dibujar el texto "Nuvee+"
      ctx.font = '10px Arial';
      ctx.fillStyle = '#000000'; // Color negro
      ctx.textAlign = 'center';
      ctx.fillText('Nuvee+', width / 2, height / 2);
    }
  }

  animate() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const leverWidth = 5;
    const leverHeight = canvas.height / 2;
    let angle = 0;

    const animateFrame = () => {
      // Limpiar el lienzo
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Dibujar la claqueta
      ctx.fillStyle = '#ffffff'; // Color blanco
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Calcular la posición de las palancas
      const leverGap = 10;
      const leverX1 = leverGap;
      const leverX2 = canvas.width - leverGap - leverWidth;
      const leverY = (canvas.height - leverHeight) / 2;

      // Dibujar las palancas con una rotación
      ctx.save(); // Guardar el estado actual del contexto
      ctx.translate(leverX1 + leverWidth / 2, leverY + leverHeight / 2); // Mover al centro de la palanca izquierda
      ctx.rotate(angle); // Rotar
      ctx.fillRect(-leverWidth / 2, -leverHeight / 2, leverWidth, leverHeight); // Dibujar la palanca izquierda
      ctx.restore(); // Restaurar el estado del contexto

      ctx.save(); // Guardar el estado actual del contexto
      ctx.translate(leverX2 + leverWidth / 2, leverY + leverHeight / 2); // Mover al centro de la palanca derecha
      ctx.rotate(-angle); // Rotar en sentido contrario
      ctx.fillRect(-leverWidth / 2, -leverHeight / 2, leverWidth, leverHeight); // Dibujar la palanca derecha
      ctx.restore(); // Restaurar el estado del contexto

      // Dibujar el texto "Nuvee+"
      ctx.font = '10px Arial';
      ctx.fillStyle = '#000000'; // Color negro
      ctx.textAlign = 'center';
      ctx.fillText('Nuvee+', canvas.width / 2, canvas.height / 2);

      // Actualizar el ángulo para la próxima animación
      angle += 0.05;

      // Solicitar el siguiente cuadro de animación
      this.animationFrameId = requestAnimationFrame(animateFrame);
    };

    // Iniciar la animación
    animateFrame();
  }

  animateGradient() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const numCols = 6;
    const numRows = 6;
    const squareSize = 25;
    const gradientSpeed = 0.5;

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
          const red = Math.floor(255 - (255 / numRows) * i);
          const green = Math.floor((255 / numCols) * j);
          ctx.fillStyle = `rgb(${red}, 0, ${green})`;
          ctx.fillRect(j * squareSize, i * squareSize, squareSize, squareSize);
        }
      }
      ctx.drawImage(canvas, -gradientSpeed, -gradientSpeed);
      this.animationFrameScreen = requestAnimationFrame(draw);
    };
    draw();
  }


  animateStatic(ctx, imageData) {
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      data[i] = Math.random() > 0.5 ? 255 : 0; 
      data[i + 1] = Math.random() > 0.5 ? 255 : 0;
      data[i + 2] = Math.random() > 0.5 ? 255 : 0;
    }
    ctx.putImageData(imageData, 12, 16);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.strokeRect(10, 14, 32, 32); 
    const x = 19;
    const y = 36;
    const width = 34;
    const height = 18;
    const greenColor = [0, 128, 0]; 
    const purpleColor = [128, 0, 128]; 
    const colorIncrement = [];
    for (let i = 0; i < 3; i++) {
      colorIncrement[i] = (purpleColor[i] - greenColor[i]) / width;
    }
    for (let i = 0; i < width; i++) {
      const r = greenColor[0] + colorIncrement[0] * i;
      const g = greenColor[1] + colorIncrement[1] * i;
      const b = greenColor[2] + colorIncrement[2] * i;
      ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
      ctx.fillRect(x + i, y, 1, height);
    }
     ctx.strokeStyle = 'black';
     ctx.lineWidth = 2;
     ctx.strokeRect(19, 36, 34, 18);
    
    ctx.font = '8px Arial';
    ctx.fillStyle = 'white';
    ctx.fillText('NuveeTv', 20, 48);
    ctx.stroke();

    this.animationFrameId = requestAnimationFrame(this.animateStatic.bind(this, ctx, imageData));
  }

  animateLoop() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const animationSpeed = 1; 

    const loop = () => {    
      for (let i = 0; i < this.currentColor.length; i++) {
        if (this.currentColor[i] < this.targetColor[i]) {
          this.currentColor[i] += animationSpeed;
        } else if (this.currentColor[i] > this.targetColor[i]) {
          this.currentColor[i] -= animationSpeed;
        }
      }
      this.draw();
      this.animationFrameId = requestAnimationFrame(loop);
    };
    loop();
  }

  clearCanvas() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    cancelAnimationFrame(this.animationFrameId);
  }

  componentWillUnmount() {
    this.clearCanvas();
  }

  render() {
    return <canvas ref={this.canvasRef} width={56} height={56} />;
  }
}

export default NavApp;

import React from 'react';

const RadiationText = () => {
  return (
    <div className="radiation-container">
      <h1 className="radiation-text">Advertise!!</h1>
    </div>
  );
};

export default RadiationText;
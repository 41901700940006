import React, { Component } from 'react'
import ReactPlayer from 'react-player';
import { LoadTv } from '../store/Metadata';
import { Col, Row, Table } from 'react-bootstrap'

export default class TvView extends Component {
  constructor(props) {
    super(props)
    this.state = LoadTv.init
  }

  changeChannel(e) {
    this.setState({
      videoUrl: e,
    })
  }


  render() {
    return (
      <div className="tv-container card-now-nohover z-25-plus">
        <div className="tv-screen">
          <ReactPlayer url={this.state.videoUrl} width="100%" height="100%" controls playing />
        </div>
        <div className="tv-channels">
          <div className="channel-scroll">
            <Table striped bordered hover variant='dark'> 
              <thead>
                <tr>
                  {this.state.channels.map((channels, k) => (
                    <th key={k} style={{backgrondColor:'transparent!important'}}>
                      <img width={'75%'} src={channels.imgSrc} onClick={() => { this.changeChannel(channels.videoUrl) }} />
                    </th>
                  ))}
                </tr>
              </thead> 
              <tbody>
                <tr>
                  {this.state.channels.map((numerChanel, k) => (
                    <td key={k} style={{backgrondColor:'transparent!important'}}>
                      {numerChanel.numbreChannel}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import axios from 'axios'
import db, { storage } from '../firebase'
import { addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { Carousel, Row, Col, Card, ModalHeader } from 'react-bootstrap'
import { Button, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap'
import { LoadHome } from '../store/Metadata'
import Imgmas from '../Assets/unmas.png'
import MetaContext from './MetaContext'
import logonuvee from '../Assets/logonuvee.png'
import { RiWhatsappLine } from "react-icons/ri";


export default class CardBuy extends Component {

  constructor(props) {
    super(props)
    this.state = LoadHome.init
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    try {
      const res = await axios.get('https://api.themoviedb.org/3/trending/all/day?api_key=e3c22a22d27dfea3ea6cce8d2a6fb2bf&language=es-ES');
      this.setState({
        resurcesTx: res.data.results,
        loadResurses: true
      });
      const res2 = await axios.get('https://api.themoviedb.org/3/movie/upcoming?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES');
      this.setState({ resurcesTxr: res2.data.results });
    } catch (err) {
      console.log(err);
    }
  }

  async handleNewUser() {
    const loadUsersFile = await doc(db, '_new_user_or', this.state.metaLoadUser.user)
    const loadFileAttribut = await doc(db, 'code_new_user', '' + this.state.keyUid)
    const docUserList = await getDoc(loadUsersFile)
    const docCode = await getDoc(loadFileAttribut)
    const loadRef = doc(db, 'usuarios_plus', this.state.metaLoadUser.user)
    const loadCode = doc(db, 'code_new_user', this.state.keyUid)
    const loadSection = await getDoc(loadRef)
    if (docUserList.exists() === false && docCode.exists()) {
      if (loadSection.exists() === true ) {
        console.log(loadSection.exists())
        alert('Usuario registrado, codigo incorrecto, favor de utilizar tu whatsapp o email')
        return
      }
      if (docCode.data().noReuseTech === true) {
        try {
          setDoc(loadRef, {
            nombreUsuario: this.state.metaLoadUser.user,
            passwordUsuario: this.state.metaLoadUser.pass,
            address: 'undefined',
            mailUsuario: 'undefined',
            levelUsuario: 'basic',
            timeExpire: '1y',
            totalCoins: 1000,
            codeNew: this.state.metaLoadUser.code,
            codeConextion: this.state.metaLoadUser.code,
            idAccount: this.state.metaId,
            otherAccounts: {},
            metaDataAccount: {
              articulos_index: { id: { nombre: 'zero', img: '', detalles: '', costo: '', track_id: '', cantidad: '', box_id: '', rack_id: '' } },
            },
          })
          setDoc(loadCode, { noReuseTech: false })
          this.handleLogin()
        }
        catch (e) {
          console.log(e)
        }
      }
    } else {
      alert('El usuario ya existe intenta con tu whatsapp o mail =) ')
    }
  }


  async handleUserState() {
    let ab = this.state.metaLoadUser
    const newVideoload = doc(db, "meta_content_files", this.state.codeEve);
    await setDoc(newVideoload, ab)
    alert('Archivos subidos')
  }

  handleClick() {
    this.setState({
      loadingUser: true,
    })
  }

  async handleOnClose() {
    this.setState({
      loadingUser: false,
    })
  }

  openWhatsApp() {
    const phoneNumber = '+528446556810'; 
    const message = 'Hola, soy el usuario ' + this.state.metaLoadUser.user + ' me estoy registrando en nuvee , pasa la clave ' + this.state.metaLoadUser.pass + ' '; 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    console.log('. . .')
  }

  whatAppSoporte() {
    const phoneNumber = '+528444950688'; 
    const message = 'Hola, Buen dia! quiero comunicarme a soporte de Nuvee '; // 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    console.log('. . .')
  }

  shekIN() {
    <MetaContext.Provider value={this.state.metaStateA}>
      {this.props.children}
    </MetaContext.Provider>
  }


  async handleLogin() {

    const userSelection = doc(db, 'usuarios_plus', this.state.metaLoadUser.user)
    const loadSection = await getDoc(userSelection)

    if (loadSection.data().nombreUsuario === this.state.metaLoadUser.user && loadSection.data().passwordUsuario === this.state.metaLoadUser.pass) {
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('userState', this.state.metaLoadUser.user);
      localStorage.setItem('codeState', this.state.metaLoadUser.code);
      localStorage.setItem('deviceName', this.state.metaLoadUser.code)
      const uperMut = doc(db, "_list_user_online", this.state.metaLoadUser.user);
      const docSnap = await getDoc(uperMut);

      if (docSnap.exists()) {
        if (docSnap.data().device === 'offline' || docSnap.data().device === this.state.metaLoadUser.code) {
          const uinEncoder = {
            user: '' + this.state.metaLoadUser.user + '',
            device: '' + this.state.metaLoadUser.code + '',
            online: '' + true + ''
          }
          await setDoc(uperMut, uinEncoder)
          window.location = '/cinuvee/'
        } else {
          this.setState({
            showModalDanger: true,
            loadingUser: false
          })
        }
      } else {
        const uinEncoder = {
          user: '' + this.state.metaLoadUser.user + '',
          device: '' + this.state.metaLoadUser.code + '',
          online: '' + true + ''
        }
        await setDoc(uperMut, uinEncoder)
        window.location = '/cinuvee/'
      }
    } else {
      alert('Credenciales incorrectas, crear un usuario')
    }
  }

  async handleCloseOldSession() {
    const uperMut = doc(db, "_list_user_online", this.state.metaLoadUser.user);
    const uinEncoder = {
      user: '' + this.state.metaLoadUser.user + '',
      device: '' + this.state.metaLoadUser.code + '',
      online: '' + true + ''
    }
    await setDoc(uperMut, uinEncoder)
    window.location = '/cinuvee/'
  }

  handleNobtn() {
    this.setState({ showModalDanger: false })
  }

  render() {
    return (
      <>
        <div className="App pordata-header">
          <div><h2 className='c-white txt-left neon-text p-absolute z-15' >Lo <span><img src={Imgmas} width={'5%'} /></span> nuevo <span><img href="/" alt='no-load.jpg' src={logonuvee} className='img-size-100 img-start' /> </span></h2>
            <div className='z-150 movie-module-container h-42'>
            </div>
          </div>
          <Modal className='modal-login' show={this.state.loadingUser} onHide={() => this.setState({ loadingUser: false })}>
            <ModalHeader closeButton ><div className='t-center'><h5 >Iniciar Sesion</h5></div></ModalHeader>
            {this.state.loginOr === true ? <><ModalBody>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Usuario"
                  aria-label="name-user-login"
                  onChange={(e) => { this.setState((st) => ({ metaLoadUser: { ...st.metaLoadUser, user: e.target.value } })) }}
                />
              </InputGroup>
              <br />
              <InputGroup>
                <FormControl
                  type="password"
                  placeholder="Password"
                  aria-label="pass-user-login"
                  onChange={(e) => { this.setState((st) => ({ metaLoadUser: { ...st.metaLoadUser, pass: e.target.value } })) }}
                />
              </InputGroup>
            </ModalBody>
              <ModalFooter>
                <Button variant='success' onClick={() => { this.handleLogin() }}>Iniciar Sesion</Button>
                <Button variant='success' onClick={() => { this.setState({ loginOr: false }) }}>Crear Usuario</Button>
              </ModalFooter></> : <><ModalBody>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Usuario"
                    aria-label="name-user-login"
                    onChange={(e) => { this.setState((st) => ({ metaLoadUser: { ...st.metaLoadUser, user: e.target.value } })) }}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <FormControl
                    type="password"
                    placeholder="Password"
                    aria-label="pass-user-login"
                    onChange={(e) => { this.setState((st) => ({ metaLoadUser: { ...st.metaLoadUser, pass: e.target.value } })) }}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Code"
                    aria-label="code-user-login"
                    onChange={(e) => { this.setState((st) => ({ keyUid: e.target.value })) }}
                  />
                </InputGroup>
                <br />
                <p style={{ cursor: 'pointer' }} onClick={() => this.openWhatsApp()}>Obtener codigo gratis por whatsapp </p>
              </ModalBody>
              <ModalFooter>
                <Button variant='success' onClick={() => { this.setState({ loginOr: true }) }}>Atras</Button>
                <Button variant='success' onClick={() => { this.handleNewUser() }}>Comenzar</Button>
              </ModalFooter></>
            }
          </Modal>
          <div className=' p-absolute z-15'>
          </div>
          <Carousel variant='dark' className='w-100 full-screen-image' onClick={() => { this.handleClick() }} >
            {this.state.loadResurses === true ?
              this.state.resurcesTx.map((a, i) => (
                <Carousel.Item key={i} interval={1500}>
                  <Card >
                    <Card.Body className='purple-card'>
                      <Row>
                        <Col>
                          <Card.Title id={i} className='txt-color-white' >{a.title}</Card.Title>

                          <Card.Img className='carousel-img img full-screen-image img' src={this.state.madeUrl + a.poster_path}></Card.Img>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Carousel.Item>
              )) : <></>}
          </Carousel>
          <div className='z-15 p-absolute w-100' >
            <div
              className='icon-container footer-whatsapp-btn l-0 t-10 neon-text p-absolute c-pointer' onClick={() => { this.whatAppSoporte() }}>
              <RiWhatsappLine />
            </div>
          </div>
        </div>
        <Modal onHide={() => this.setState({ showModalDanger: false })} className='z-15' show={this.state.showModalDanger}>
          <ModalTitle>Al tiro que no te hackeen</ModalTitle>
          <ModalBody>
            <p>Deseas cerrar session en los otros dispositivos ?</p>
          </ModalBody>
          <ModalFooter>
            <Button className='b-s-o-c' onClick={() => { this.handleCloseOldSession() }}>Si</Button>
            <Button className='b-s-o-c' onClick={() => { this.handleNobtn() }}>No</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

import React, { Component } from 'react'
import { Button, Card, CardBody, CardFooter, FormControl, InputGroup } from 'react-bootstrap'
import { LoadSagasName } from '../../../store/Metadata'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import db from '../../../firebase'

export default class NameSaga extends Component {
    constructor(props) {
        super(props)
        this.state = LoadSagasName.init
    }

    async componentDidMount() {

        const sagasList = await getDocs(collection(db, 'sagas_names'));


        try {
            sagasList.forEach((nsame, index, inside) => {
                /* this.setState((prevState) => ({
                     loadList : [...prevState, nsame]
                 }))*/
                console.log(nsame.data().noString)
            })

        } catch (e) { }

    }

    handleBtnNewNameSagas() {

        if (this.state.nameSagas !== '') {

            const SagasUpload = doc(db, 'sagas_names', this.state.nameSagas)

            try {
                setDoc(SagasUpload, {
                    nameSagas: this.state.nameSagas,
                    idSagas: this.state.codeEve,
                    portadaSagas: '',
                })
                let cleanTxt = document.getElementById('#namesagas')
                cleanTxt.value = ''
                this.setState({
                    nameSagas: ''
                })
            } catch (e) {

            }
        }
    }




    render() {
        return (
            <div style={{ marginLeft: '5%' }}>
                <Card>
                    <CardBody>
                        <span>peliculas agregadas</span>
                        <span>ESRB</span>
                        <InputGroup>
                            <select onChange={(e) => { this.setState({ nameSagas: e.target.value }) }}>
                                {this.state.loadList.map((nameSagas, id) => (
                                    <option key={id}>{nameSagas}</option>
                                ))}
                            </select>
                        </InputGroup>
                        <br />
                        <InputGroup>
                            <FormControl
                                id='nameSagas'
                                type='text'
                                placeholder='Ingresa el nombre de la Sagas'
                                onChange={(e) => { this.setState({ nameSagas: e.target.value }) }}
                            />
                        </InputGroup>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.handleBtnNewNameSagas() }}>Agregar Sagas</Button>
                    </CardFooter>
                </Card>
            </div>
        )
    }

}
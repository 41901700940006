import React, { Component } from 'react'

export default class PrivacyPolitc extends Component {
  render() {
    return (
      <div><header></header>
      <div>
        <p>
        Normas de Privacidad de Usuario

Última actualización: 20 de Marzo del 2024 

Estas normas de privacidad de usuario describen cómo GRUPO NUVEE ("nosotros", "nuestro", "nosotros") recopila, utiliza y comparte información personal de los usuarios ("usuarios", "usted") de nuestro sitio web y servicios relacionados (en adelante, "Servicios"). Respetamos su privacidad y nos comprometemos a proteger su información personal de acuerdo con las leyes aplicables, incluido el GDPR y el CPRA. Al utilizar nuestros Servicios, usted acepta las prácticas descritas en estas normas de privacidad.

1. Información que recopilamos

Información proporcionada por el usuario: Recopilamos información que usted nos proporciona voluntariamente, como su nombre, dirección de correo electrónico, información de contacto y cualquier otra información que nos envíe a través de formularios en nuestro sitio web.

Información recopilada automáticamente: Podemos recopilar información sobre su uso de nuestros Servicios de forma automática, incluida su dirección IP, tipo de navegador, páginas visitadas, duración de la visita y otra información de análisis.

2. Uso de la información

Utilizamos la información que recopilamos para los siguientes propósitos:

Proporcionar y mantener nuestros Servicios.
Personalizar la experiencia del usuario y ofrecer contenido relevante.
Comprender cómo los usuarios interactúan con nuestro sitio web y mejorar nuestros Servicios.
Enviar comunicaciones promocionales y actualizaciones relacionadas con nuestros productos y servicios, siempre que usted haya dado su consentimiento para recibir dichas comunicaciones.
3. Compartir de la información

No vendemos, alquilamos ni compartimos información personal de usuarios con terceros, excepto en los siguientes casos:

Cuando sea necesario para cumplir con la ley, responder a procesos legales o proteger nuestros derechos legales.
Con proveedores de servicios que nos ayudan a operar nuestros Servicios, siempre que se comprometan a mantener la confidencialidad de la información.
4. Cookies y tecnologías similares

Utilizamos cookies y tecnologías similares para mejorar la funcionalidad de nuestro sitio web y proporcionar una experiencia de usuario personalizada. Puede configurar su navegador para rechazar todas las cookies o para que le avise cuando se envíe una cookie. Sin embargo, si desactiva las cookies, es posible que algunas partes de nuestro sitio web no funcionen correctamente.

5. Sus derechos de privacidad

Usted tiene ciertos derechos en relación con su información personal, incluido el derecho a acceder, corregir, eliminar y restringir el procesamiento de sus datos personales. Para ejercer estos derechos, póngase en contacto con nosotros utilizando la información de contacto que se proporciona al final de estas normas de privacidad.

6. Cambios en estas normas de privacidad

Nos reservamos el derecho de actualizar estas normas de privacidad en cualquier momento y sin previo aviso. Se le notificarán los cambios significativos a estas normas de privacidad mediante la publicación de la versión actualizada en nuestro sitio web.

7. Contacto

Si tiene alguna pregunta o inquietud sobre estas normas de privacidad o sobre nuestras prácticas de privacidad en general, no dude en ponerse en contacto con nosotros a través de [startnuvee@gmail.com].
        </p>
      </div>
      <footer></footer></div>
    )
  }
}

import React, { Component } from 'react';
import imageCompression from 'browser-image-compression';


class ToolsUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalImage: null,
      compressedImage: null,
    };
  }

  handleImageChange = async (e) => {
    const imageFile = e.target.files[0]
    if (!imageFile) {
      return
    }

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 240,
        useWebWorker: true,
      };

      const compressedImage = await imageCompression(imageFile, options)

      this.setState({
        originalImage: URL.createObjectURL(imageFile),
        compressedImage: URL.createObjectURL(compressedImage),
      });
    } catch (error) {
      console.error('Error compressing image:', error)
    }
  };

  handleDownload = () => {
    const { compressedImage } = this.state;
    if (compressedImage) {
      const link = document.createElement('a')
      link.href = compressedImage;
      link.download = 'logo.jpg'
      link.click()
    }
  };

  render() {
    return (
      <div>
        <input type="file" onChange={this.handleImageChange} />
        {this.state.originalImage && (
          <div>
            <p>Imagen original:</p>
            <img src={this.state.originalImage} alt="Original" />
          </div>
        )}
        {this.state.compressedImage && (
          <div>
            <p>Imagen comprimida:</p>
            <img src={this.state.compressedImage} alt="Comprimida" />
            <button onClick={this.handleDownload}>Descargar Imagen Comprimida</button>
          </div>
        )}
        <div className="slider-container">
        </div>
      </div>

    )
  }
}

export default ToolsUse
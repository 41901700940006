import React, { Component } from 'react';
import YouTube from 'react-youtube'; // Asegúrate de haber instalado react-youtube

class YoutubeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyVideos: this.props.keyVideo,
      isLoading: true,
    };
   }  

    render() {
      const opts = {
        height: '390',
        width: '420',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
  
      return (
        <div>
          <YouTube videoId={this.props.keyVideo} opts={opts} onReady={this._onReady} />
        </div>
      );
    }
  }
  
  export default YoutubeVideo;
import React, { Component } from 'react'
import {GrCircleAlert} from 'react-icons/gr'
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import UploadServer from './UploadServer'
import { LoadHomepage} from '../../store/Metadata'



export default class Homepage extends Component {
  constructor(props){
    super(props)
    this.state = LoadHomepage.init
    this.handleButtonLng = this.handleButtonLng.bind(this)
  }

  componentDidMount(){
  
  }

  handleButtonLng(a,b){
    if(a===this.state.user&& b===this.state.secury){
      console.log('succes')
      this.setState({
      loginSuccess : true,
      loginFnc : (e,b,r)=>{
        e===this.state.codeIs ? b===this.state.xFormat ? <> { r = (this.state.cblogin) }</>: <></> :<></> ;
        this.setState=({ chgState : r,  }) },
      siono: true ,
      chgState:true,
    })
    }
    console.log(this.state.chgState)
  }
  render() {
    return (
      <div className='z-25-plus' style={{placeSelf:'center'}}>
        {this.state.chgState===false ?  <Card className='card-login'>
            <CardHeader title='Acceso Restringido'><span><GrCircleAlert/></span> Acceso Restringido <span><GrCircleAlert/></span></CardHeader>
            <CardBody>                
                <InputGroup className='input-control'>
                   <FormControl
                    placeholder='Usuario'
                    type='text'                    
                    onChange={(e)=>{this.setState({usuario: (e.target.value)})}}
                   />
                </InputGroup>                 
                <InputGroup className='input-control'>
                   <FormControl
                    placeholder='Contraseña'
                    type='password'                   
                    onChange={(e)=>{this.setState({password: (e.target.value)})}}
                   />
                </InputGroup>                
            </CardBody>
            <CardFooter>
                <Button onClick={()=>{this.handleButtonLng(this.state.usuario,this.state.password)}} variant='success'>Verificar</Button>
            </CardFooter>
        </Card>:<></> }
        {this.state.loginSuccess === true ? <><UploadServer/> </>:<></> }
      </div>
    )
  }
}

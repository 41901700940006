import React from 'react';

const GlitchText = () => {
  return (
    <p className="glitch">
        <span aria-hidden="true">Anunciate !!!</span>
        Anunciate !!!
        <span aria-hidden="true">Anunciate !!!</span>
    </p>
  );
};

export default GlitchText;

import React, { Component } from 'react'

export default class SpinnerPod extends Component {
  render() {
    return (
      <div className="  App-header App">
        <div className="spinner"></div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import SpotifyAds from './Moduls/SpotifyAds'
import SpinnerPod from './Moduls/SpinnerPod'
import Backgrounds from './Moduls/Backgrounds'
import ArtistFm from './Moduls/ArtistFm'
import { Button, Col, Container, Nav, Navbar, NavDropdown, Row, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BsShare, BsPlusCircle, BsFillPauseCircleFill, BsCheckCircleFill, BsHeart, BsHeartFill, BsFillVolumeOffFill, BsFillVolumeUpFill, BsPlayCircle, BsPlayCircleFill, BsShareFill } from 'react-icons/bs'
import { LoadRadio } from '../store/Metadata'
import { FaPlay, FaPause, FaStop, FaVolumeUp } from 'react-icons/fa';


export default class Podcast extends Component {
  constructor(props) {
    super(props)
    this.state = LoadRadio.init
    this.state = {
      loadSpinner: true
    }
    this.state = {
      mostrarMensaje: false,
      creditosDisponibles: 10, // Supongamos que el usuario tiene 10 créditos disponibles inicialmente.
    };
  }
  
  componentDidMount() {
    setTimeout((() => { this.setState({ loadSpinner: false }); console.log('') }), 2300)
  }


  handlePlayClick = () => {
    this.setState({ mostrarMensaje: true });
  };

  handleContinuarClick = () => {

    this.setState({ mostrarMensaje: false });
  };

  handleCancelarClick = () => {

    this.setState({ mostrarMensaje: false });
  };


  pause(){

  }

  play(){
    
  }



  render() {
    return (
      <div>
        {this.state.loadSpinner === true ? <SpinnerPod /> : <></>
        }
        <div>
          <div>
            <div>
              <div>
                <section>
                  <div>
                    <img id="radio-logo" src="https://cdn.webrad.io/images/logos/emisoras-com-mx/exa-104-9.png" alt="Exa" height="66" importance="high" width="96" />
                    <Row>
                      <Col style={{ cursor: 'pointer' }}>
                        <div>
                          <audio
                            ref={this.state.audioPlayer}
                            onTimeUpdate={this.state.onPlaying}
                            style={{ display: 'none' }}
                            src='https:\\playerservices.streamtheworld.com\pls\LOS40_MEXICOAAC.pls'></audio>
                          <Card onClick={() => {
                            this.state.statePlay == false ? this.play() : this.pause()
                          }} >
                            <Card.Img style={{ zIndex: '5', borderRadiusBottomleft: '0px!important', borderRadiusBottomleft: '0px!imporant' }} src={this.state.imgSample}></Card.Img>
                            <Card.Text><span style={{ bottom: '4.5%', left: '20%', fontSize: '3em', color: 'gray', zIndex: '25' }}>{
                              this.state.statePlay == false ? <BsPlayCircle /> : <BsFillPauseCircleFill />
                            }</span><span style={{ fontSize: '0.7em', zIndex: '25', bottom: '20px', position: 'absolute' }}>Ahora suena: </span> </Card.Text>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import purpleImgCard from '../Assets/purplecard.png'
import { addDoc,collection,doc,getDoc,setDoc } from 'firebase/firestore';
import {BsColumnsGap,BsCameraVideo,BsViewStacked,BsPersonBadge,BsListStars,BsPalette,BsJournalText} from 'react-icons/bs'
import db from '../firebase'
import Greycard from '../Assets/cardbackground.png'
import { useEffect, useState } from 'react';
import PublicidadNuvee from '../Assets/publicidadnuve.jpg'
import Azteca7Img from '../Assets/logocanal/azteca7.jpg'
import Canal5Img from '../Assets/logocanal/canal5.jpg'
import EsportImg from '../Assets/logocanal/esport2.jpg'
import Foxsport from '../Assets/logocanal/foxsport.jpg'
import FightNetImg from '../Assets/logocanal/fightnetwork.jpg'
import RCCImg from '../Assets/logocanal/rcc.jpg'
import TvIcon from '../Assets/navicons/tvicon.png'
import Esport2Img from '../Assets/logocanal/esport4.jpg'
import MasCiclismoTVImg from '../Assets/logocanal/masciclismo.jpg'
import AccionTv from '../Assets/logocanal/acction.jpg'
import Bandamax from '../Assets/logocanal/bandamax.jpg'
import HBO2 from '../Assets/logocanal/hbo2.jpg'
import GoldenEdge from '../Assets/logocanal/goldenedge.png'
import RedBullImg from '../Assets/logocanal/redbull.jpg'
import ToxicRapImg from '../Assets/logocanal/xltoxic.jpg'
import RockTVImg from '../Assets/logocanal/rock.jpg'
import SolMusicImg from '../Assets/logocanal/sol.jpg'
import CartoonNetwork from '../Assets/logocanal/cn.jpg'
import envirImg001 from '../Assets/logomovies/001.jpg'
import envirImg002 from '../Assets/logomovies/005.jpg'
import envirImg003 from '../Assets/logomovies/003.jpg'
import envirImg004 from '../Assets/logomovies/004.png'
import envirImg005 from '../Assets/logomovies/004.jpg'
import miniImg001 from '../Assets/logomovies/mini001.jpg'
import miniImg002 from '../Assets/logomovies/mini002.jpg'
import miniImg003 from '../Assets/logomovies/mini003.jpg'
import miniImg004 from '../Assets/logomovies/mini004.jpg'
import miniImg005 from '../Assets/logomovies/mini005.jpg'
import miniImg006 from '../Assets/logomovies/mini006.jpg'
import logoNuvee from '../Assets/logonuvee.png'
import profile00 from '../Assets/defaultimg/defaultf01.png'
import profile01 from '../Assets/defaultimg/defaultf02.png'
import profile02 from '../Assets/defaultimg/defaultf03.png'
import profile03 from '../Assets/defaultimg/defaultf04.png'
import profile04 from '../Assets/defaultimg/defaultf05.png'
import profile05 from '../Assets/defaultimg/defaultm01.png'
import profile06 from '../Assets/defaultimg/defaultm02.png'
import profile07 from '../Assets/defaultimg/defaultm03.png'
import profile08 from '../Assets/defaultimg/defaultm04.png'
import profile09 from '../Assets/defaultimg/defaultm05.png'
import Arcade from '../Assets/games/controlarcade.jpg'
import Consola from '../Assets/games/controlconsola.jpg'
import MzvTag from '../Assets/mzv.png'


const newCoinMk = (mainOff)=>{
  let selectMainInfo = mainOff           
  let seedTok = ['0','1','2','3','4','5','6','7','8','9']
  let makeToken = ""
  seedTok.map(dates=>{            
      let min = 0
      let max = 9
      let rand =  (Math.random()*(max-min))+min
      makeToken += seedTok[rand.toFixed(0)]            
  })
  let seedKey = ['a','b','c','d','e','f','g','0','1','2','3','4','5','6','7','D','A','B','T']
  let makeKey = ""
  seedKey.map(dates=>{            
      let min = 0
      let max = 18
      let rand =  (Math.random()*(max-min))+min
      makeKey += seedKey[rand.toFixed(0)]    
      return makeKey                 
  })
  return { makeKey }
 
 }

export const MetaStore = {
    navOpselector : {
      mainOption :  ['Nuvee TV','Cinuvee +','Play'],  
      urlSelect : ['/NuveeTv','/Cinuvee','/Gamers'],
      unicodeList : '_online_state_true' ,
      metaId : newCoinMk().makeKey,
      metaDeviceName : false,
      logoNuveeOn : logoNuvee,
      modalAbout : false,
      iconTv : TvIcon,
      TagMzv : MzvTag,
    }
}

export const LoadAdminProfile = {
  init : {
    userProfileImg :purpleImgCard,
    nameUser : 'undefined',
    direction : 'undefined',
    realName : 'undefined',
    planUser : 'basic',
    email : 'undefined',
    metaUserData : [{moreUsers:''},],
    colorBackground : 'normal',
    profileImg :Greycard,
    metaUserLoad :  [{}],
    modalPasswordERSB :false,
    passwordERSB: '',
    ERSBactived:false,
  }
}

export const LoadRouter = {
  init : {
    metaId : newCoinMk().makeKey
  }
}

export const LoadNavApp = {
  init : {
    icons : [
      {name:'NuveeTv',imgSrc:TvIcon},],
    iconTv : TvIcon,


  }
}

export const LoadHome = {
  init : {
    madeUrl : 'http://www.themoviedb.org/t/p/w1280',
    purpleCard : purpleImgCard,
    userImgSample : Greycard,
    imgSample : Greycard,
    loadResurses : false,
    docs: [],
    cardImg : '',
    idCard : '',
    otherInf : '',
    onSecureUid : false,
    urlVideoNoCodec : '',
    showInfo : false,
    createVideoBlob : '',
    metadata: [],
    codeNew : '',
    codeConextion : '',
    loadingUser  : false,
    loginOr : true ,
    metaLoadUser : {user:'undefined',pass:'undefined',code:newCoinMk().makeKey},
    keyUid : '',
    metaId : newCoinMk().makeKey,
    storeImg : [
      {name:'Disney+',pathImg:envirImg001 },
      {name:'Net',pathImg:envirImg002 },
      {name:'Hbo',pathImg:envirImg003 },
      {name:'Para',pathImg:envirImg004 }, 
      {name:'Para',pathImg:envirImg005 }, 
    ]   ,
    miniStoreImg : [
      {name:'disney',pathImg:miniImg001 },
      {name:'netflix',pathImg:miniImg003 },  
      {name:'max',pathImg:miniImg002 },         
      {name:'paramount',pathImg:miniImg004 }, 
      {name:'prime',pathImg:miniImg005 }, 
      {name:'nuvee',pathImg:miniImg006 },
    ]   , 
    maxNumberLikeIt: 0,
    togLikeIt :false,
    originalImage: null,
    compressedImage: null,
    capConList : [],
  }
}

export const LoadCinuvee = {
  init : {
    code : undefined,
    movieId : undefined,
    madeUrl : 'http://www.themoviedb.org/t/p/w1280',
    dataUrl : 'https://www.youtube.com/watch?v=',
    keyUrl : undefined,
    imgMovie : undefined,
    loadResurses : false,
    metadata : [],
    loadImg : Greycard,
    urlCode : '',
    docs : [],
    openGame: false,
  }
}

export const LoadTv = {
  init : {    
    numberChanner : [ '0','1'],
    channels :[     
      {name:'Canal 5',imgSrc:Canal5Img,videoUrl:'https://channel05.akamaized.net/hls/live/2033783/event01/index.m3u8',numbreChannel:'105',parternalBlock:false},
      {name:'Azteca 7',imgSrc:Azteca7Img,videoUrl:'https://mdstrm.com/live-stream-playlist/609ad46a7a441137107d7a81.m3u8',numbreChannel:'107',parternalBlock:false}, 
      /*{name:'GoldenEDGE',imgSrc:GoldenEdge,videoUrl:'https://linear-407.frequency.stream/mt/studio/407/hls/master/playlist.m3u8',numbreChannel:'410',parternalBlock:false},    */
      /*{name:'HBO',imgSrc:HBO2,videoUrl:'https://stream.flynetwifi.com:1936/live/mobile-094/playlist.m3u8',numbreChannel:'417',parternalBlock:false},    
      {name:'FoxSport',imgSrc:Foxsport,videoUrl:'https://stream.flynetwifi.com:1936/live/mobile-010/playlist.m3u8',numbreChannel:'505',parternalBlock:false}, */
      {name:'RedBull',imgSrc:RedBullImg,videoUrl:'https://d12a2vxqkkh1bo.cloudfront.net/hls/main.m3u8',numbreChannel:'512',parternalBlock:true},
      {name:'ToxicRap',imgSrc:ToxicRapImg,videoUrl:'https://linear-418.frequency.stream/mt/studio/418/hls/master/playlist.m3u8',numbreChannel:'755',parternalBlock:false},
      {name:'SolMusic',imgSrc:SolMusicImg,videoUrl:'https://d2glyu450vvghm.cloudfront.net/v1/master/3722c60a815c199d9c0ef36c5b73da68a62b09d1/cc-21u4g5cjglv02/sm.m3u8',numbreChannel:'770',parternalBlock:false},
     /* {name:'Bandamax',imgSrc:Bandamax,videoUrl:'https://linear-400.frequency.stream/mt/studio/400/hls/master/playlist.m3u8',numbreChannel:'779',parternalBlock:true},*/
      {name:'RockSpot',imgSrc:RockTVImg,videoUrl:'https://tv.broadcasting.ro/rocktv/85c83a80-4f71-4f2d-a8d6-43f676896bcb.m3u8',numbreChannel:'788',parternalBlock:false},
            
    ] ,    
  }
}

export const LoadUpload = {
  init : {
    editionMain : undefined,
    keyId : undefined,
    freshToken : undefined,
    keyImg : undefined,
    mainCategory : undefined,
    mainArtist : undefined,
    newViedoUp : undefined,
  }
}

export const LoadAuthKey = {
  init : {
    authKey : newCoinMk().makeKey,
  }
}

export const LoadHomepage = {
  init : {
    codeIs : undefined,
    chgState : false,
    cblogin : undefined,
    xFormat : undefined,
    keyImg : undefined,
    freshToken : undefined,
    newViedoUp : true,
    dashboardFrame : ['Dasboard', 'Admin de contenido', 'Nuevo Video', 'Contenido', 'Artistas', 'Categorias','Clasificaciones','Portadas','Fuetes'],
    user : process.env.REACT_APP_USER_INT,
    secury : process.env.REACT_APP_GET_PASS+''+process.env.REACT_APP_GET_WORD,   
  }
}

export const LoadSerieName =  {
  init: {
    nameSerie: '',
    category :'',
    totalCap : 0,
    codeEve:newCoinMk().makeKey,
    loadList:[], 
    serieListX :    [],
  }
}

export const LoadSagasName =  {
  init: {
    nameSagas: '',
    category :'',
    totalCap : 0,
    codeEve:newCoinMk().makeKey,
    loadList:[], 
    sagasListX :    [],
  }
}


export const LoadSidebar = {
  icon : {
    dashVideoIcon: [
      { name: 'Dashboard', icon: <BsColumnsGap className="icon-sidebar" /> },
      { name: 'Admin de contenido', icon: <BsCameraVideo className="icon-sidebar" /> },
      { name: 'Nuevo Video', icon: <BsViewStacked className="icon-sidebar" /> },
      { name: 'Contenido', icon: <BsPersonBadge className="icon-sidebar" /> },
      { name: 'Artistas', icon: <BsListStars className="icon-sidebar" /> },
      { name: 'Categorias', icon: <BsPalette className="icon-sidebar" /> },
      { name: 'Nombre Series', icon: <BsJournalText className="icon-sidebar" /> },
      { name: 'Sagas por mintear', icon: <BsPalette className="icon-sidebar" /> },
      { name: 'Fuetes', icon: <BsJournalText className="icon-sidebar" /> },
    ],
    dashGameIcon: [
      { name: 'Dashboard', icon: <BsColumnsGap className="icon-sidebar" /> },
      { name: 'Admin de juegos', icon: <BsCameraVideo className="icon-sidebar" /> },
      { name: 'Enrutar Rom', icon: <BsViewStacked className="icon-sidebar" /> },
      { name: 'NFT', icon: <BsPersonBadge className="icon-sidebar" /> },
      { name: 'Desarrollado', icon: <BsListStars className="icon-sidebar" /> },
      { name: 'Categorias', icon: <BsPalette className="icon-sidebar" /> },
      { name: 'Clasificaciones', icon: <BsJournalText className="icon-sidebar" /> },      
    ],
    selectOption : undefined,
    changeOption : undefined,
    actOption : 'Dashboard',
    codeEnvitormet : undefined,
    videoSection: true,
    gameSection :false,
    tvSection : false,
    musicSection : false,
    mainOption :  ['Stream TV','Cinuvee +','Podcast','Gamers'], 

  }
}

export const LoadNewVideo = {
  init : {
    idMovie : '',
    controlPaternal : false,
    codeMovie : 'undefined',
    categoriesLoad :['Accion','Drama','Infantil','Ciencia Ficcion','Aventura','Suspenso','Documental'],
    catMovie : false,
    catSerie : false,
    catShowTv : false,
    codeEve : newCoinMk().makeKey,
    capNo : '',   
    ERSBLoad : 'undefined',
    ersbCategory : 'undefined',
    ERSBstate: false,
    imgTitle : Greycard,
    imgThumb : Greycard,
    imgLogo : Greycard,
    loadCategoryOption : ['Pelicula','Serie','Contenido TV'],  
    madeUrl : 'http://www.themoviedb.org/t/p/w1280',
    movieDetails : '',
    maturieLevel : ['All','7+','13+','16+','18+'],
    media_type : '',
    nameSaga : '',
    noSeasson : ['Temporada 0','Temporada 1','Temporada 2','Temporada 3','Temporada 4','Temporada 5','Temporada 6','Temporada 7'],
    noCapitulo : Array.from({ length: 101 }, (_, index) => index),   
    trailerCorrect : 'undefined',
    togLive : false,
    titleMovie :  '',
    searchMovie : '',     
    lookDetails : false,     
    thumbsAut : 'undefined',
    option: ['Agregar Video','Administrar Videos','Publicados','Sin publicar','Similares','Basurero','En vivo','Tag Artistas','Tag Tv','Tag Series'],
    putCategory : 'undefined',
    plataformChose : '',
    plataformLevel : ['Crunchyroll','Disney+','Netflix','Prime','HBO','Paramount','MGM','NUVEE+'],
    realTitleMovie : 'undefined',
    radioValue : '',
    showProgres : 'undefined',
    setVideoServer : 'undefined',
    showDoneTxt : false,
    selectDb : 'category',
    serieName:'undefined',
    serieList:{},
    sagaName:'',
    sagaList:{},
    selectionAuto : [],
    sagaMain:false, 
    seassonNo : '', 
    unkwnowed : 'undefined',
    urlDescarga : '',
  }
}

export const LoadContent = {
  init : {
    docs: [],
    cardImg : '',
    idCard : '',
    otherInf : '',
    urlVideoNoCodec : '',
    showInfo : false,
    createVideoBlob : '',
    metadata: [],    
  }
}

export const LoadRadio = {
  init : {
    imgSample : profile00,
    audioPlayer : '',
    stationSelect : ['93.3','101.1','103.4'],
    imgSample : profile01,
    statePlay : false,
    onPlaying : '',
    audioPlayer : '',
  }
}


export const LoadAdminGame = {
  init : {
    nameGame : '',
    ERSB : ['E','E+','Teen','Mature','Adults Only 18+'],
    gama : ['Baja','Media','Alta','Ultra'],
    maturieLevel : ['All','7+','13+','16+','18+','Rating Pending','Rating Pending+'],
    consoleOnPlay : ['Nintendo','Super Nintendo','Sega','SNK','GameboySP','N64'],
    ERSBLoad : 'E',
    gameGama : 'Baja',
    cosoleOver : 'Nintendo',
    imgThumb : Greycard,
    codeEve : newCoinMk().makeKey,
    urlGame : '',
    profileImg : PublicidadNuvee,
  }
}

export const LoadGames = {
  init : {
    urlCode : '',
    metadata : '',
    loadImg : '',
    loadResurses : false,
    docs : [],
    showInfo : '',
    urlGamePlay : '',
    imgArcade : Arcade,
    imgConsole : Consola,
    consoleOrArcade : false,
    mainArcade : false ,
    mainConsole : false ,
  }
}



import React, { Component } from 'react';

class BackgroundSVG extends Component {
    render() {
        return (
            <div style={{ position: 'absolute', zIndex: 500, inset: 0 }}>
                <svg height="100%" width="100%" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(255, 0, 255, 0.8)" />
                            <stop offset="100%" stopColor="rgba(255, 0, 255, 0)" />
                        </radialGradient>

                        {/* Gradiente 2 */}
                        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(205, 170, 238, 0.8)" />
                            <stop offset="100%" stopColor="rgba(255, 255, 0, 0)" />
                        </radialGradient>

                        {/* Gradiente 3 */}
                        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(133, 73, 182, 0.8)" />
                            <stop offset="100%" stopColor="rgba(0, 255, 255, 0)" />
                        </radialGradient>

                        {/* Gradiente 4 */}
                        <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(205, 170, 238, 0.8)" />
                            <stop offset="100%" stopColor="rgba(0, 255, 0, 0)" />
                        </radialGradient>

                        {/* Gradiente 5 */}
                        <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(45, 140, 170, 0.8)" />
                            <stop offset="100%" stopColor="rgba(0, 0, 255, 0)" />
                        </radialGradient>

                        {/* Gradiente 6 */}
                        <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5">
                            <animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite" />
                            <stop offset="0%" stopColor="rgba(247, 142, 30, 0.8)" />
                            <stop offset="100%" stopColor="rgba(255, 0, 0, 0)" />
                        </radialGradient>

                    </defs>
                    {/* Primer rectángulo */}
                    <rect
                        x="13.744%"
                        y="1.18473%"
                        width="100%"
                        height="100%"
                        fill="url(#Gradient1)"
                        transform="rotate(334.41 50 50)"
                    >
                        <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
                        <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            dur="7s"
                            repeatCount="indefinite"
                        />
                    </rect>
                    {/* Segundo rectángulo */}
                    <rect
                        x="-2.17916%"
                        y="35.4267%"
                        width="100%"
                        height="100%"
                        fill="url(#Gradient2)"
                        transform="rotate(255.072 50 50)"
                    >
                        <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite" />
                        <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite" />
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            dur="12s"
                            repeatCount="indefinite"
                        />
                    </rect>

                    {/* Tercer rectángulo */}
                    <rect
                        x="9.00483%"
                        y="14.5733%"
                        width="100%"
                        height="100%"
                        fill="url(#Gradient3)"
                        transform="rotate(139.903 50 50)"
                    >
                        <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite" />
                        <animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite" />
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="360 50 50"
                            to="0 50 50"
                            dur="9s"
                            repeatCount="indefinite"
                        />
                    </rect>
                    {/* Otros rectángulos con animaciones similares */}
                    {/* ... */}
                </svg>
            </div>
        );
    }
}

export default BackgroundSVG;

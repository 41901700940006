import { Firestore, collection, doc, getDoc, getDocs } from 'firebase/firestore'
import React, { Component } from 'react'
import db from '../../../firebase'
import { LoadContent } from '../../../store/Metadata'
import { Card, CardBody, CardHeader, CardImg, FormControl, InputGroup, Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import ReactPlayer from 'react-player'

export default class AdminVideo extends Component {
    constructor(props){
        super(props) 
        this.state = LoadContent.init; 
    }

    async componentDidMount(){
        const docs = [];
        const extractList = collection(db,'meta_content_files')
        const querySnapshot = await getDocs(collection(db, 'meta_content_files'));
        querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, data: doc.data() });
        this.setState({ docs });
        });
      }

     async loadOptionFile(dc){
        
        const loadFileAttribut = await doc(db,'meta_content_files',dc)
        const docSnap = await getDoc(loadFileAttribut);
        this.setState({
            showInfo:true,
            urlVideoNoCodec : docSnap.data().urlDescarga,
            metadata : docSnap.data(),
        }) 
     }


  render() {
    const { docs,showInfo, urlVideoNoCodec } = this.state;
    return (
               
      <div className='justify-container-center j-i-c' >
        {docs.map((doc,idx) => (
        <Card key={idx} className='c-pointer w-50 ' onClick={()=>{this.state.showInfo = true ;this.loadOptionFile(doc.id)}} >
            <CardHeader className='c-white'>{doc.data.titleMovie}</CardHeader>
            <CardBody>
                <CardImg src={doc.data.imgTitle} width={'25%'}/>
            </CardBody>
        </Card>
      ))}
      <Modal onHide={()=>this.setState({showInfo:false})} show={showInfo}>
        <ModalHeader closeButton />
        <ModalBody>
             <ReactPlayer width={'100%'} url={this.state.urlVideoNoCodec} controls={true}/>
             <br/>
             <p>Cambiar Atributos</p>
             <InputGroup>
                <FormControl 
                  
                />
             </InputGroup>
             
        </ModalBody>        
      </Modal>
      </div>
    )
  }
}

import React, { useEffect, useRef } from "react";

const Backgrounds = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth-300;
    canvas.height = window.innerHeight-5;

    const hexagons = [];
    const hexSize = 30;
    const hexWidth = hexSize * 2;
    const hexHeight = Math.sqrt(3) * hexSize;
    const cols = 6;
    const rows = 34;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        const x = j * hexWidth * 1.5 + (i % 2 === 1 ? hexWidth * 0.75 : 0);
        const y = i * hexHeight * 0.5;
        const hex = {
          x,
          y,
          size: hexSize,
          colors: ["#6495ed", "#67fd65", "#0e005c"],
        };
        hexagons.push(hex);
      }
    }

    function drawHexagon(hex) {
      ctx.beginPath();
      const angleOffset = -Math.PI / 6;
      for (let i = 0; i < 6; i++) {
        const angle = i * Math.PI / 3 + angleOffset;
        const x = hex.x + hex.size * Math.cos(angle);
        const y = hex.y + hex.size * Math.sin(angle);
        ctx.lineTo(x, y);
      }
      ctx.closePath();
      const gradient = ctx.createLinearGradient(
        hex.x - hex.size,
        hex.y - hex.size,
        hex.x + hex.size,
        hex.y + hex.size
      );
      gradient.addColorStop(0, hex.colors[0]);
      gradient.addColorStop(0.5, hex.colors[1]);
      gradient.addColorStop(1, hex.colors[2]);
      ctx.fillStyle = gradient;
      ctx.fill();
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      hexagons.forEach((hex) => {
        const angleInRadians = (50 * Math.PI) / 180; 
        const spacing = 0.5; 
        hex.x += 2 * Math.cos(angleInRadians) ;
        hex.y -= 5 * Math.sin(angleInRadians) ;
        if (hex.y < -hex.size) {
          hex.x = Math.random() * window.innerWidth + 45;
          hex.y = window.innerHeight + hex.size ;
        }
        drawHexagon(hex);
      });
    }

    animate();
  }, []);

  return <canvas className="p-absolute d-block" ref={canvasRef} />;
};

export default Backgrounds;
import React, { Component } from 'react'
import { LoadNewVideo } from '../../../store/Metadata'
import db, { storage } from '../../../firebase'
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { Button, Card, Col, Form, FormCheck, FormControl, InputGroup, Row, ToggleButton } from 'react-bootstrap'
import SearchCodeMov from './SearchCodeMov'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'

export default class NewVideo extends Component {
  constructor(props) {
    super(props)
    this.state = LoadNewVideo.init
    this.putOption = this.putOption.bind(this);
    this.loadAutoDetails = this.loadAutoDetails.bind(this);

  }

  async componentDidMount() {
    const serieInit = []
    const sagaInit = []
    this.uploadToServer()
    this.state = await LoadNewVideo.init
    const serieListX = await getDocs(collection(db, 'series_names'));
    const sagasListX = await getDocs(collection(db, 'sagas_names'));

    sagasListX.forEach((ssame, index, inside) => {
      sagaInit.push({ sagaNames: ssame.data().nameSagas });
      this.setState((prevState) => ({
        sagaInit,
      }));
    })


    serieListX.forEach((nsame, index, inside) => {
      serieInit.push({ serieNames: nsame.data().nameSerie });

      this.setState((prevState) => ({
        serieInit,
      }));
    })


  }

  async uploadToServer() {
    const refDb = await doc(db, this.state.selectDb, this.state.selectDb)
  }

  async loadAutoDetails() {
    fetch(`https://api.themoviedb.org/3/search/multi?api_key=00f019191c205c1208fd3d615b9fb303&language=es-ES&query=${this.state.searchMovie}&page=1&include_adult=false`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          selectionAuto: data.results
        })
        if (data.results.length === 0) {

        }
      });
  }

  getImgTitle() {
    let putImg = document.querySelectorAll('#titleImg')
    putImg[0].click()
  }

  getImgLogo() {
    let putImg = document.querySelectorAll('#logoImg')
    putImg[0].click()
  }

  getImgThumb() {
    let putImg = document.querySelectorAll('#viewImg')
    putImg[0].click()
  }

  getVideoTo() {
    let putImg = document.querySelectorAll('#loadVideo')
    putImg[0].click()
  }

  readyToCachtVideo() {
    console.log('========')
  }

  async putOption(e) {
    const data = await e
    if (data.name === undefined) {
      try {
        this.setState({
          movieDetails: data.overview,
          lookDetails: false,
          titleMovie: data.title,
          imgTitle: this.state.madeUrl + '' + data.poster_path,
          imgLogo: this.state.madeUrl + '' + data.poster_path,
          imgThumb: this.state.madeUrl + '' + data.backdrop_path,
          media_type: data.media_type,
          controlPaternal: this.state.controlPaternal,
          idMovie: data.id,
          serieList: {
            capN: this.state.capNo,
            urlDescarga: this.state.urlDescarga,
          }
        })
        this.readyToCachtVideo()
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        this.setState({
          movieDetails: data.overview,
          lookDetails: false,
          titleMovie: data.name,
          imgTitle: this.state.madeUrl + '' + data.poster_path,
          imgLogo: this.state.madeUrl + '' + data.poster_path,
          imgThumb: this.state.madeUrl + '' + data.backdrop_path,
          media_type: data.media_type,
          idMovie: data.id,
          serieList: {
            capN: this.state.capNo,
            urlDescarga: this.state.urlDescarga,
          }
        })
        this.readyToCachtVideo()
      } catch (e) {
        console.log(e)
      }

    }
    e.media_type === 'tv' ? this.setState({ radioValue: 'Contenido TV' }) : e.media_type === "movie" ? this.setState({ radioValue: 'Pelicula' }) : e.media_type === 'serie' ? this.setState({ radioValue: 'Serie' }) : <></>
  }

  async getUrlTitle() {
    const loadResurses = () => {
      try {
        getDownloadURL(ref(storage, 'video/Thumbs/Portada/' + this.state.codeEve + '/portada.jpg'))
          .then((url) => {
            this.setState({
              imgTitle: url,
            })
          }).catch((error) => {
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    }
    loadResurses()
  }

  async pushTitleImg(c) {
    let fileData = c.target.files[0]
    this.setState({
      imgTitle: c.target.files[0],
    })
    const storageRef = ref(storage, 'video/Thumbs/Portada/' + this.state.codeEve + '/portada.jpg');
    uploadBytes(storageRef, fileData).then((snapshot) => {
      this.getUrlTitle()
    })
  }

  getUrlLogo() {

  }

  pushLogoImg(c) {
    let fileData = c.target.files[0]
    this.setState({
      imgLogo: c.target.files[0],
    })
    const storageRef = ref(storage, 'video/Thumbs/Logo/' + this.state.codeEve + '/portada.jpg');
    uploadBytes(storageRef, fileData).then((snapshot) => {

    })
  }

  pushThumbImg(c) {

    let fileData = c.target.files[0]
    this.setState({
      imgThumb: c.target.files[0],
    })
    const storageRef = ref(storage, 'video/Thumbs/Backpath/' + this.state.codeEve + '/portada.jpg');
    uploadBytes(storageRef, fileData).then((snapshot) => {
      // console.log(snapshot.metadata.fullPath);            
    })
  }

  async uploadData() {    
    let ab = this.state
    const newVideoload = doc(db, "meta_content_files", this.state.titleMovie);
    const getFileSerie = await getDoc(newVideoload)
    const getFileSaga = await getDoc(newVideoload)
    this.setState({
      serieList: {
        cN: [{
          seassonN: this.state.nameSaga,
          capNx: this.state.capNo,
          urlDownload: this.state.urlDescarga,
        }]
      }
    })
    if (this.state.radioValue === 'Serie') { 
      const serieListUpdate = {
        seassonN: this.state.seassonNo,
        capN: this.state.capNo,
        urlDescarga: this.state.urlDescarga,
        
      };
      if (getFileSerie.exists()) {
        await updateDoc(newVideoload, {
          serieList: {
            ...getFileSerie.data().serieList,
            [this.state.capNo]: serieListUpdate,
          },
        });
        alert('Archivos subidos')
        setTimeout(() => { this.state = LoadNewVideo.init }, 500)
      } else {
        await setDoc(newVideoload, ab)
        alert('Archivos subidos')
        setTimeout(() => { this.state = LoadNewVideo.init }, 500)
      }


    }if(this.state.sagaMain === true) {
      const sagaListUpdate = {
        sagaNameS: this.state.nameSaga,
        titleMovieS: this.state.titleMovie,
        urlDescarga: this.state.urlDescarga,
        
      };
      if (getFileSaga.exists()) {
        await updateDoc(newVideoload, {
          sagaList: {
            ...getFileSaga.data().sagaList,
            [this.state.nameSaga]: sagaListUpdate,
          },
        });
        alert('Archivos subidos')
        setTimeout(() => { this.state = LoadNewVideo.init }, 500)
      } else {
        await setDoc(newVideoload, ab)
        alert('Archivos subidos')
        setTimeout(() => { this.state = LoadNewVideo.init }, 500)
      }    
      
    } else {
      let ab = this.state
      const newVideoload = doc(db, "meta_content_files", this.state.codeEve);
      await setDoc(newVideoload, ab)
      alert('Archivos subidos')
      setTimeout(() => { this.state = LoadNewVideo.init }, 500)
    }
  }

  pushVideoLoad = async (e) => {

    let fileData = e.target.files[0]
    if (fileData) {
      const storageRef = ref(storage, 'video/Peliculas/' + this.state.codeEve + '/movie.mp4');
      const uploadTask = uploadBytesResumable(storageRef, fileData);
      uploadTask.on('state_changed',
        (snapshot) => {
          const progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ progreso });
          console.log('Upload is ' + progreso + '% done');
        },
        (error) => {
          console.error('Error al subir el archivo', error);
        },
        () => {
          // Subida completada exitosamente
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.setState({ urlDescarga: downloadURL });
            console.log('done!video')
          });
        }
      );
    }
  };

  handleRatioChange() {

    this.state.sagaInit.map((i) => {
    })
  }


  render() {
    return (
      <div className='justify-container-center' style={{ height: '80vh', overflowY: 'scroll', paddingRight: '2.5%' }}>NewVideo
        <div>
          <Row>
            <Col>
              <span>Titulo del contenido</span>
              <InputGroup>
                <FormControl
                  placeholder={this.state.titleMovie}
                  type='text'
                  onChange={(e) => { this.setState({ searchMovie: e.target.value, lookDetails: true }); this.loadAutoDetails(); }}
                />
              </InputGroup>
              {this.state.lookDetails === true ?
                <div className="frame-show-detail" >
                  {this.state.selectionAuto.map(movieDB => (
                    <SearchCodeMov
                      movie={movieDB}
                      key={movieDB.id}
                      chooseMovie={this.putOption}
                    />
                  ))}
                </div> : <></>}
              <span>Descripcion</span>
              <InputGroup>
                <FormControl
                  placeholder={this.state.movieDetails}
                />
              </InputGroup>
              <span>Categoria</span>
              <InputGroup>
                <span>
                  <FormControl
                    type='text'
                    defaultValue={this.state.putCategory}
                    style={{ display: 'none' }}
                  /></span>
                <select onChange={(e) => { this.setState({ putCategory: e.target.value }) }}>
                  {this.state.categoriesLoad.map((categorias, ind) => (
                    <option key={ind} >{categorias}</option>
                  ))}
                </select>
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="ERSB activar"
                    onChange={(e) => { e.target.checked ? this.setState({ ERSBstate: true }) : this.setState({ ERSBstate: false }) }}
                  />
                </Form>
              </InputGroup>
              <span>ESRB</span>
              <InputGroup>
                <select onChange={(e) => { this.setState({ ERSBLoad: e.target.value }) }}>
                  {this.state.maturieLevel.map((esrb) => (
                    <option key={esrb}>{esrb}</option>
                  ))}
                </select>
              </InputGroup>
              <InputGroup>               
                <br />
                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Saga"
                    onChange={(e) => { e.target.checked ? this.setState({ sagaMain: true }) : this.setState({ sagaMain: false }) }}
                  />
                </Form>
              </InputGroup>
              {this.state.sagaMain === true ? <InputGroup>
                  <select onChange={(e) => { this.setState({ nameSaga: e.target.value }) }}>
                    {this.state.sagaInit.map((saga, index) => (
                      <option key={index}>{saga.sagaNames}</option>
                    ))}
                  </select>
                </InputGroup> : null}
              <p>Disponivilidad</p>
              {/**menu de si esta en peliculas series o tv o todas */}
              <Row>
                {this.state.loadCategoryOption.map((radio, idx) => (
                  <Col key={idx}>
                    <ToggleButton
                      className="mb-2"
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant="outline-primary"
                      name="radio"
                      value={radio}
                      checked={this.state.radioValue === radio}
                      onChange={(e) => { this.setState({ radioValue: e.currentTarget.value }); }}
                    >{radio}</ToggleButton>
                  </Col>
                ))}
              </Row>
              {this.state.radioValue === 'Serie' ? <>
                <InputGroup>
                  <select onChange={(e) => { this.setState({ seassonNo: e.target.value }) }}>
                    {this.state.noSeasson.map((noS) => (
                      <option key={noS}>{noS}</option>
                    ))}
                  </select>
                </InputGroup>
                <InputGroup>
                  <FormCheck
                    disabled
                    type="switch"
                    label="ESRB Active/Desactive"
                    onChange={(e) => { this.setState({ controlPaternal: e.target.value }) }}
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <select onChange={(e) => { this.setState({ nameSerie: e.target.value }) }}>
                    {this.state.serieInit.map((serie, index) => (
                      <option key={index}>{serie.serieNames}</option>
                    ))}
                  </select>
                </InputGroup>
                <br />
                <InputGroup>
                  <select onChange={(e) => { this.setState({ capNo: e.target.value }) }}>
                    {this.state.noCapitulo.map((noS) => (
                      <option key={noS}>{noS}</option>
                    ))}
                  </select>
                </InputGroup>
              </> : <></>}
              <InputGroup>
                <select onChange={(e) => { this.setState({ plataformChose: e.target.value }) }}>
                  {this.state.plataformLevel.map((plataform) => (
                    <option key={plataform}>{plataform}</option>
                  ))}
                </select>
              </InputGroup>
              <Row style={{ marginTop: '50px!important' }}>
                <Col>
                  <Card className='thumb-img' onClick={() => this.getImgTitle()}>
                    <Card.Img
                      className='card-in-thumb' src={this.state.imgTitle}></Card.Img>
                    <span className='card-span-in'>Ratio: 9:16 Recomendado 1080 x 1920 Image: JPEG</span>
                    <br></br>
                  </Card>
                </Col>
                <Col>
                  <div >
                    <Card className='thumb-img'>
                      <Card.Img
                        className='card-in-thumb' src={this.state.imgThumb}></Card.Img>
                      <span className='card-span-in'>Recomendado: 500px de ancho, Formato PNG con transparencia</span>
                    </Card>
                  </div>
                  <br></br>
                  <div >
                    <Card className='thumb-img'>
                      <Card.Img
                        className='card-in-thumb' src={this.state.imgLogo}></Card.Img>
                      <span className='card-span-in'>Thumbs ratio 16:9 Recomendado 1920 x 1080, Image: JPEG</span>
                    </Card>
                  </div>
                  <Form.Control
                    style={{ display: 'none' }}
                    id='titleImg'
                    type='file'
                    accept='image/png,image/jpeg'
                    onChange={(e) => this.pushTitleImg(e)}
                  />
                  <Form.Control
                    style={{ display: 'none' }}
                    id='logoImg'
                    type='file'
                    accept='image/png,image/jpeg'
                    onChange={(e) => this.pushLogoImg(e)}
                  />
                  <Form.Control
                    style={{ display: 'none' }}
                    id='viewImg'
                    type='file'
                    accept='image/png,image/jpeg'
                    onChange={(e) => this.pushThumbImg(e)}
                  />
                </Col>
              </Row>
              <br></br>
              <Button style={{ width: '100%' }} onClick={() => { this.getVideoTo() }}>Seleccionar Video</Button>
              <Form.Control
                style={{ display: 'none' }}
                id='loadVideo'
                type='file'
                onChange={(e) => this.pushVideoLoad(e)}
              />
            </Col>
          </Row>
          <br />
        </div>
        {this.state.endLoad === true ? <>{this.uploadData()}</> : <></>}
        <InputGroup>
          <FormControl
            type='text'
            placeholder='input souce video'
            onChange={(e) => {
              this.setState((prevState) => ({
                ...prevState,
                urlDescarga: e.target.value,
                serieList: {
                  capN: this.state.capNo,
                  urlDescarga: e.target.value,
                }
              }));
            }}
          />
        </InputGroup>
        <Button onClick={() => { this.uploadData() }}>Subir</Button>
        <br />
      </div>
    )
  }
}

import { Firestore, collection, doc, getDoc, getDocs } from 'firebase/firestore'
import React, { Component } from 'react'
import db from '../../../firebase'
import { LoadContent } from '../../../store/Metadata'
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, FormControl, InputGroup, Modal, ModalBody, ModalHeader, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'

export default class ContentSqare extends Component {
    constructor(props){
        super(props) 
        this.state = LoadContent.init; 
    }

    async componentDidMount(){
        const docs = [];
        const extractList = collection(db,'meta_content_files')
        const querySnapshot = await getDocs(collection(db, 'meta_content_files'));
        querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, data: doc.data() });
        this.setState({ docs });
        });
      }

     async loadOptionFile(dc){
        
        const loadFileAttribut = await doc(db,'meta_content_files',dc)
        const docSnap = await getDoc(loadFileAttribut);
        this.setState({
            showInfo:true,
            urlVideoNoCodec : docSnap.data().urlDescarga,
            metadata : docSnap.data(),
        }) 
     }


  render() {
    const { docs,showInfo, urlVideoNoCodec } = this.state;
    return (
               
      <div className='justify-container-center j-i-c' >
       <Row>
        <Col>
          <Card>
            <CardTitle></CardTitle>
          </Card>
        </Col>
       </Row>
      </div>
    )
  }
}

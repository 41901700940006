import React from 'react';


const NeonText = () => {
  return (
    <div className="neon-container">
      <h1 className="neon-texts">推進する！ ！ ！</h1>
    </div>
  );
};

export default NeonText;
import React, { Component } from "react";
import db from "../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { LoadGames } from "../store/Metadata";
import { IoIosArrowBack } from "react-icons/io";
import { Card, CardBody, CardFooter, CardHeader, CardImg, Col, Modal, ModalBody, ModalHeader, ModalTitle, Row } from "react-bootstrap";

class Gamers extends Component {
  constructor(props) {
    super(props);
    this.emulator = null
    this.state = LoadGames.init
  }

  async componentDidMount() {
    const docs = [];
    const extractList = collection(db, 'meta_game_files')
    const querySnapshot = await getDocs(collection(db, 'meta_game_files'));
    querySnapshot.forEach((doc) => {
      docs.push({ id: doc.id, data: doc.data() });
      this.setState({ docs });
    });
  }

  async loadOptionFile(dc) {
    const loadFileAttribut = await doc(db, 'meta_game_files', dc)
    const docSnap = await getDoc(loadFileAttribut);
    this.setState({
      showInfo: true,
      urlGamePlay: docSnap.data().urlGame,
      metadata: docSnap.data(),
    })
  }

  render() {
    const { docs, showInfo } = this.state;
    return (
      <>
        <div className="z-25 w-20" style={{display:'ruby'}}>
          {this.state.consoleOrArcade === true ?
            <Row>
              <span onClick={() => { this.setState({ consoleOrArcade: false }) }}><IoIosArrowBack /></span>
              <br />
              {docs.map((doc, idx) => (
                <Col key={idx} >
                  <Card className='c-pointer w-20 ' onClick={() => { this.state.mainConsole = true; this.loadOptionFile(doc.id) }} >
                    <CardHeader className='c-white'><p>{doc.data.nameGame}</p></CardHeader>
                    <CardBody>
                      <CardImg src={doc.data.imgThumb} width={'25%'} />
                    </CardBody>
                  </Card>
                </Col>
              ))}</Row>
            : <Row>
              <Col>
                <Card className='c-pointer w-20 card-now' onClick={() => { this.setState({ consoleOrArcade: true }) }}>
                  <CardImg src={this.state.imgArcade} />
                </Card>
              </Col>
              <Col>
                <Card className='c-pointer w-20 card-now' onClick={() => { this.setState({ consoleOrArcade: true }) }}>
                  <CardImg src={this.state.imgConsole} />
                </Card>
              </Col>
            </Row>}
          <Modal className="w-100 height-100 z-25-plus" onHide={() => this.setState({ showInfo: false })} show={showInfo}>
            <ModalHeader closeButton>{this.state.nameGame}</ModalHeader>

            <ModalBody className="w-100 h-420">
              <iframe src={this.state.urlGamePlay} className="w-100 height-100"></iframe>
            </ModalBody>
          </Modal>
        </div>
      </>
    )
  }
}

export default Gamers;
import React, { Component } from 'react'
import { LoadAdminGame } from '../../../store/Metadata'
import { Col, FormControl, InputGroup, Row, Card, Button, Form } from 'react-bootstrap'
import db, { storage } from '../../../firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { doc, setDoc } from 'firebase/firestore'

export default class AdminGame extends Component {
    constructor(props) {
        super(props)
        this.state = LoadAdminGame.init
    }

    async toServer() {
        let ab = this.state
        const newGameSet = doc(db, "meta_game_files", this.state.codeEve);
        await setDoc(newGameSet, ab)
        alert('Archivos subidos')
    }

    extraerURLDesdeTexto(texto) {
        // Expresión regular para encontrar URLs
        const regex = /(https?:\/\/[^\s]+)/g;
        // Buscar coincidencias en el texto
        const matches = texto.match(regex);
        // Devolver la URL encontrada (si hay alguna)
        return matches ? matches[0] : null;
    }

    async getUrlTitle() {
        const loadResurses = () => {
            try {
                getDownloadURL(ref(storage, 'videogame/Thumbs/Portada/' + this.state.codeEve + '/portada.jpg'))
                    .then((url) => {
                        this.setState({
                            imgThumb: url,
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
            } catch (e) {
                console.log(e)
            }
        }
        loadResurses()
    }

    async pushTitleImg(c) {
        let fileData = c.target.files[0]
        const storageRef = ref(storage, 'videogame/Thumbs/Portada/' + this.state.codeEve + '/portada.jpg');
        uploadBytes(storageRef, fileData).then((snapshot) => {
            this.getUrlTitle()
        })
    }

    getImgTitle() {
        let putImg = document.querySelectorAll('#titleImg')
        putImg[0].click()
    }


    render() {
        return (
            <div className='justify-container-center'>
                <div>
                    <Row>
                        <Col>
                            <h5>Agregar ruta de rom</h5>
                            <InputGroup>
                                <FormControl
                                    type='text'
                                    placeholder='Nombre del juego'
                                    onClick={(e) => { this.setState({ nameGame: e.target.value }) }}
                                />
                            </InputGroup>
                            <span>ESRB</span>
                            <InputGroup>
                                <select onChange={(e) => { this.setState({ ERSBLoad: e.target.value }) }}>
                                    {this.state.ERSB.map((esrb) => (
                                        <option key={esrb}>{esrb}</option>
                                    ))}
                                </select>
                            </InputGroup>
                            <span>Gama de cel</span>
                            <InputGroup>
                                <select onChange={(e) => { this.setState({ gameGama: e.target.value }) }}>
                                    {this.state.gama.map((gama) => (
                                        <option key={gama}>{gama}</option>
                                    ))}
                                </select>
                            </InputGroup>
                            <br />
                            <div style={{ justifyContent: 'center', display: 'flex', alignContent: 'center' }}>
                                <Card className='thumb-img w-20'>
                                    <br />
                                    <Card.Img
                                        className='card-in-thumb' src={this.state.imgThumb} onClick={() => { this.getImgTitle() }}></Card.Img>
                                    <span className='card-span-in'>Recomendado: 500px de ancho, Formato PNG con transparencia</span>
                                </Card>
                            </div>
                            <Form.Control
                                style={{ display: 'none' }}
                                id='titleImg'
                                type='file'
                                accept='image/png,image/jpeg'
                                onChange={(e) => this.pushTitleImg(e)}
                            />
                            <br></br>
                            <InputGroup>
                                <FormControl
                                    type='text'
                                    placeholder='Agregar Hyperviculo del rom'
                                    aria-label='hypervinculo'
                                    onChange={(e) => { this.setState({ urlGame: e.target.value }) }}
                                />
                            </InputGroup>
                            <br />
                            <span>Plataforma</span>
                            <InputGroup>
                                <select onChange={(e) => { this.setState({ consoleOver: e.target.value }) }}>
                                    {this.state.consoleOnPlay.map((onPlay, k) => (
                                        <option key={k}>{onPlay}</option>
                                    ))}
                                </select>
                            </InputGroup>
                            <br />
                            <Button variant='success' onClick={() => { this.toServer() }}>Agregar</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

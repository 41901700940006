import React, { Component } from 'react'
import { Button, Card, Col, Navbar, NavLink, Row, Nav, InputGroup, FormControl, Dropdown } from 'react-bootstrap'

import { BsColumnsGap, BsCameraVideo, BsViewStacked, BsPersonBadge, BsListStars, BsPalette, BsJournalText } from 'react-icons/bs'

import { LoadHomepage } from '../../store/Metadata'
import Sidebar from '../Moduls/Sidebar'

const options = ['Dasboard', 'Admin de contenido', 'Nuevo Video', 'Contenido', 'Artistas', 'Categorias', 'Clasificaciones', 'Portadas', 'Fuetes'];

export default class UploadServer extends Component {

  constructor(props) {
    super(props)
    this.state = LoadHomepage.init
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className='main-over'>

        <Sidebar options={options} />

      </div>
    )
  }
}







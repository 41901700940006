import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Cinuvee from './Component/Cinuvee'
import TvView from './Component/TvView'
import App from './App'
import PrivacyPolitc from './PrivacyPolitc'
import MainSelector from './Component/MainSelector'
import Gamers from './Component/Gamers'
import Podcast from './Component/Podcast'
import Backgrounds from './Component/Moduls/Backgrounds'
import Homepage from './Component/Mapp/Homepage'
import Portada from './Component/Portada'
import ToolsUse from './Component/Moduls/TVFrame/ToolsUse'
import AdminProfile from './Component/Moduls/ProfileFrame/AdminProfile'
import CardBuy from './Component/CardBuy'
import BackgroundSVG from './BackgroudSVG'



export default class RouterApp extends Component {


  render() {
    return (
      <>
      <BackgroundSVG className="App-header"/>
        {/* Controla la visualización del MainSelector dependiendo de la ruta */}
        {window.location.pathname !== '/' && window.location.pathname !== '/homepagemaszov' && <MainSelector />}
        <div className="App App-header">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Portada />} />
              <Route path='/Cinuvee' element={<App />} />
              <Route path='/cinuvee/:movie/:id' element={<Cinuvee />} />
              <Route path='/Profile' element={<AdminProfile />} />
              <Route path='/NuveeTV' element={<TvView />} />
              <Route path='/Gamers' element={<Gamers />} />
              <Route path='/Musica' element={<Podcast />} />
              <Route path='/ModuleTest' element={<Backgrounds />} />
              <Route path='/homepagemaszov' element={<Homepage />} />
              <Route path='/Tools' element={<ToolsUse />} />
              <Route path='/Cardmade' element={<CardBuy/> } />
              <Route path='/privacitynuvee' element={<PrivacyPolitc/>} />
            </Routes>
          </BrowserRouter>
        </div>

      </>
    )
  }
}

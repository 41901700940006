import React, { useEffect } from 'react';
import './App.css';
import Home from './Component/Home';



function App() {


  return (
    <>
      <div >
       <Home/>     
      </div>     
    </>   
  );
}

export default App;
